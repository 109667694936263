import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import TextField from "components/ui/controls/text-field/text-field";
import StateBox from "components/ui/controls/state-box/state-box";

const DateDepartRetraiteCumulEmploi = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <blockquote>
                La date de départ en retraite à saisir correspond à la date de liquidation définitive des droits retraite. Cette date ne peut jamais être inférieure à la date de départ initialement renseignée dans la carrière.
                </blockquote>
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <StateBox
                  label="A l'âge de"
                  inline
                  value={context.state["CumulEmploiDepartAgeChoisi" + ClientConjoint]}
                  onClick={context.handleDepartRetraiteCumulEmploi(ClientConjoint, "CumulEmploiDepartAgeChoisi")}
                  normal
                />
              </Column>
              <Column>
                <TextField
                  value={context.state["CumulEmploiDepartAgeChoisiSelect" + ClientConjoint]}
                  onChange={context.handleDepartRetraiteCumulEmploi(ClientConjoint, "CumulEmploiDepartAgeChoisiSelect")}
                  xs
                  textAlign="center"
                />
              </Column>
              <Column style={{ alignSelf: "center" }}>ans et</Column>
              <Column>
                <TextField
                  value={context.state["CumulEmploiDepartMoisChoisiSelect" + ClientConjoint]}
                  onChange={context.handleDepartRetraiteCumulEmploi(ClientConjoint, "CumulEmploiDepartMoisChoisiSelect")}
                  xs
                  textAlign="center"
                />
              </Column>
              <Column style={{ alignSelf: "center" }}>mois</Column>
            </Row>
            <Row tpad>
              <Column>
                <StateBox
                  label="A la date du"
                  inline
                  value={context.state["CumulEmploiDepartDateChoisi" + ClientConjoint]}
                  onClick={context.handleDepartRetraiteCumulEmploi(ClientConjoint, "CumulEmploiDepartDateChoisi")}
                  normal
                />
              </Column>
              <Column>
                <TextField value="01" disabled xs textAlign="center" />
              </Column>
              <Column style={{ marginTop: "8px" }}>/</Column>
              <Column>
                <TextField
                  value={context.state["CumulEmploiMoisDepartDateChoisiInput" + ClientConjoint]}
                  onChange={context.handleDepartRetraiteCumulEmploi(ClientConjoint, "CumulEmploiMoisDepartDateChoisiInput")}
                  xs
                  textAlign="center"
                />
              </Column>
              <Column style={{ marginTop: "8px" }}>/</Column>
              <Column>
                <TextField
                  value={context.state["CumulEmploiAnneeDepartDateChoisiInput" + ClientConjoint]}
                  onChange={context.handleDepartRetraiteCumulEmploi(ClientConjoint, "CumulEmploiAnneeDepartDateChoisiInput")}
                  dataType="uint"
                  xs
                  textAlign="center"
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default DateDepartRetraiteCumulEmploi;
