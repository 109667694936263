import { useState, useEffect, useContext } from "react";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import StateBox from "components/ui/controls/state-box/state-box";
import { applyFormat } from "components/ui/ui-helpers";
import { CorrespondanceActivite } from "components/app/RemExpertIntialState";
import _ from "lodash";

const AlertLURAModalCumulEmploi = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [idsPeriodeLURA, setIdsPeriodeLURA] = useState({});

  useEffect(() => {
    let ids = {};
    context.state.CumulEmploisIdsLURA?.map((id, index) => {
      if (index === 0) {
        ids[id] = true;
      } else {
        ids[id] = false;
      }
    });
    setIdsPeriodeLURA(ids);
  }, [props.visible]);

  const handleCheckIdLURA = (idPeriode) => {
    let ids = _.cloneDeep(idsPeriodeLURA);
    Object.keys(idsPeriodeLURA)?.map((id) => {
      if (Number(idPeriode) === Number(id)) {
          ids[id] = true;
      } else {
        ids[id] = false;
      }
    });
    setIdsPeriodeLURA(ids);
  };

  const handleValidateLURA = () => {
    let data = _.cloneDeep(context.state);
    Object.keys(idsPeriodeLURA)?.map((id) => {
      if (idsPeriodeLURA[id] === false) {
        data["CumulEmploiCarriere" + props.ClientConjoint] = data["CumulEmploiCarriere" + props.ClientConjoint].filter((Periode) => Periode.id !== Number(id));
      }
    });
    context.handleCalculCER(props.ClientConjoint, data);
  };

  return (
    <>
      <Pager
        pages={[
          {
            content: (
              <>
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row tpad>
                        <Column>
                          <blockquote>
                            Les instructions règlementaires sur le calcul de la nouvelle pension dans le cadre de la LURA n'ont pas encore été communiquées par l'Assurance retraite. Par
                            conséquent, nous avons fait le choix d'empêcher la possibilité de saisir une double activité d'indépendant et de salarié. Une modification logicielle sera
                            apportée dès parution de la circulaire.<br/>
                            Dans l'attente de la mise à jour, nous vous invitons à sélectionner l'une des deux périodes que vous souhaitez projeter dans le cadre du CER.
                          </blockquote>
                        </Column>
                      </Row>
                      <Row tpad>
                        <Row fill tpad style={{ maxHeight: "500px", overflow: "auto" }}>
                          <table>
                            <thead>
                              <tr>
                                <th>Date de début</th>
                                <th>Date de fin</th>
                                <th>Activité</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {context.state["CumulEmploiCarriere" + props.ClientConjoint]?.map((Infos, Index) => {
                                return (
                                  context.state.CumulEmploisIdsLURA.indexOf(Infos.id) > -1 && (
                                    <tr key={Index}>
                                      <>
                                        <td> {applyFormat(Infos.Debut, "date", "")} </td>
                                        <td>{applyFormat(Infos.Fin, "date", "")}</td>
                                      </>

                                      <td>{CorrespondanceActivite[Infos.Nature]}</td>
                                      <td>
                                        <StateBox
                                          value={idsPeriodeLURA?.[Infos.id]}
                                          // value={true}
                                          onClick={() => {
                                            handleCheckIdLURA(Infos.id);
                                          }}
                                          normal
                                        />
                                      </td>
                                    </tr>
                                  )
                                );
                              })}
                            </tbody>
                          </table>
                        </Row>
                        <Column></Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              </>
            ),
          },
        ]}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          // cancel: {},
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              handleValidateLURA();
              // context.handleCalculCumulEmploiRetraite(props.ClientConjoint, data);
              // context.handleCalculCumulEmploiRetraite(props.ClientConjoint, context.state);
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default AlertLURAModalCumulEmploi;
