import { useState, useEffect, useContext } from "react";
import { FaAward, FaCalculator, FaCalendar, FaChartArea, FaFileSignature, FaLandmark, FaUpload } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import DateDepartRetraite from "components/app/AuditComplet/Retraite/Parametrage/DateDepart/DateDepartRetraite";
import MajorationEnfantRetraite from "components/app/AuditComplet/Retraite/Parametrage/Majorations/MajorationEnfantRetraite";
import TauxRemplacement from "components/app/AuditComplet/Retraite/Parametrage/TauxRemplacement/TauxRemplacement";
import PrelevementsSociaux from "components/app/AuditComplet/Retraite/Parametrage/PrelevementsSociaux/PrelevementSociaux";
import PointRetraite from "components/app/AuditComplet/Retraite/Parametrage/Points/PointRetraite";
import LectureRG from "components/app/AuditComplet/Retraite/Parametrage/ReleveRG/LectureRG";
import { RGContext } from "components/app/AuditComplet/Retraite/Parametrage/ReleveRG/RGProvider";
import DateDepartRetraiteCumulEmploi from "./DateDepartRetraite cumul emploi";
import RegmimesLiquidablesCumulEmploi from "./Regimes liquidables cumul emploi";

const ParamRetraitePageModalCumulEmploi = (props) => {
  const context = useContext(AppContext);
  const RGCtx = useContext(RGContext);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.visible]);
  return (
    <>
      <Pager
      id="paramRetraitePager"
        withMenu
        pages={[
          {
            isTitle: true,
            title: "Paramètres",
            subTitle: "Paramètres de la retraite",
          },
          {
            label: "Date de départ",
            icon: <FaCalendar />,
            content: <DateDepartRetraiteCumulEmploi Outil="" ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Régimes liquidables",
            icon: <FaCalculator />,
            content: <RegmimesLiquidablesCumulEmploi Outil="" ClientConjoint={props.ClientConjoint}/>,
          },
        ]}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          // cancel: {},
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {

                context.handleCalculCumulEmploiRetraite(props.ClientConjoint,context.state);
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default ParamRetraitePageModalCumulEmploi;
