import React, { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Row from "components/ui/layout/row/row";
// import ModalPeriodeRetraite from "./ModalPeriodeRetraite";

const PeriodeCumulEmploi = (props) => {
  const context = useContext(AppContext);

  useEffect(() => {
    if (props.visible === true) {
      // context.handleCreationRevenusRetraite("RetraiteDebutActivite",context.state.RetraiteDebutActivite)
    }
  }, [context.state.RetraiteActivite, props.visible]);

  const handleDateDebut = (date) => {
    let AnneeDebutPeriode = date.slice(4);
    let MoisDebutPeriode = date.slice(2, 4);
    let JourDebutPeriode = date.slice(0, 2);

    let AnneeMini = context.state["DateDepartRetraiteAffichage" + props.ClientConjoint].slice(-4);
    let MoisMini = context.state["DateDepartRetraiteAffichage" + props.ClientConjoint].slice(3, 5);
    let JourMini = context.state["DateDepartRetraiteAffichage" + props.ClientConjoint].slice(0, 2);

    console.log(AnneeDebutPeriode, MoisDebutPeriode, JourDebutPeriode, AnneeDebutPeriode + MoisDebutPeriode + JourDebutPeriode);
    console.log(context.state["DateDepartRetraiteAffichage" + props.ClientConjoint], AnneeMini, MoisMini, JourMini, AnneeMini + MoisMini + JourMini);
    if (Number(AnneeDebutPeriode + MoisDebutPeriode + JourDebutPeriode) < Number(AnneeMini + MoisMini + JourMini)) {
      context.handleCreationRevenusRetraite("RetraiteDebutActivite", JourMini + MoisMini + AnneeMini);
    } else {
      context.handleCreationRevenusRetraite("RetraiteDebutActivite", date);
    }
  };

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <TextField
                  label="A partir du"
                  disabled={context.state.RISAuto === "RISAuto" ? true : false}
                  value={context.state.RetraiteDebutActivite}
                  // onBlur={context.handleCreationRevenusRetraite("RetraiteDebutActivite")}
                  onBlur={(evt, unformatted) => {
                    handleDateDebut(unformatted);
                  }}
                  dataType="date"
                />
              </Column>
              <Column>
                <DropDown
                  label="&nbsp;"
                  value={context.state.CheckboxDepartRetraite}
                  disabled={context.state.RISAuto === "RISAuto" ? true : false}
                  onChange={context.handleCheckboxDepartRetraite(props.ClientConjoint)}
                  options={[
                    { value: true, label: "Départ en retraite" },
                    { value: false, label: "Personnalisé" },
                  ]}
                  normal
                />
              </Column>
              <Column>
                <TextField
                  label="Jusqu'au"
                  disabled={context.state.RISAuto === "RISAuto" || context.state.CheckboxDepartRetraite === true ? true : false}
                  value={context.state.RetraiteFinActivite}
                  onBlur={(evt, unformatted) => {
                    context.handleCreationRevenusRetraite("RetraiteFinActivite", unformatted);
                  }}
                  dataType="date"
                />
              </Column>
            </Row>
            {/* <ModalPeriodeRetraite visible={context.state.OpenAlertDialogPeriodeRetraite} onClose={context.handleDialogClose('OpenAlertDialogPeriodeRetraite')} /> */}
            <Row>
              <Column>
                <DropDown
                  label="Activité"
                  value={context.state.RetraiteActivite}
                  disabled={
                    context.state.RISAuto === "RISAuto" &&
                    context.state.RetraiteActivite !== "Maladie" &&
                    context.state.RetraiteActivite !== "Maternite" &&
                    context.state.RetraiteActivite !== "Invalidite"
                      ? true
                      : false
                  }
                  onChange={context.handleSelectActiviteRetraite("RetraiteActivite")}
                  options={[
                    {
                      label: "Activités salariées",
                      options: [
                        {
                          label: "Salarié non cadre du secteur privé",
                          value: "SalarieNonCadre",
                        },
                        { label: "Salarié cadre du secteur privé", value: "SalarieCadre" },
                        {
                          label: "Salarié non cadre du secteur agricole",
                          value: "SalarieNonCadreAgricole",
                        },
                        {
                          label: "Salarié cadre du secteur agricole",
                          value: "SalarieCadreAgricole",
                        },
                        { label: "Salarié non cadre cotisant CFE", value: "SalarieNonCadreCFE" },
                        { label: "Salarié cadre cotisant CFE", value: "SalarieCadreCFE" },
                        { label: "Salarié non titulaire de l’Etat", value: "SalarieIrcantec" },
                        { label: "Expert comptable salarié", value: "CavecSalarie" },
                      ],
                    },
                    {
                      label: "Activités non salariées",
                      options: [
                        { label: "Agent général", value: "Cavamac" },
                        { label: "Artisan", value: "RsiArtisan" },
                        { label: "Avocat", value: "Cnbf" },
                        { label: "Auxiliaire médical", value: "Carpimko" },
                        { label: "Chirurgien-dentiste", value: "Carcdsf" },
                        { label: "Commerçant", value: "RsiCommercant" },
                        { label: "Expert-comptable", value: "Cavec" },
                        { label: "Ingénieur conseil", value: "Cipav" },
                        { label: "Médecin", value: "Carmf" },
                        { label: "Officier ministériel, public ou judiciaire", value: "Cavom" },
                        { label: "Sage-femme", value: "Carcdsf2" },
                        { label: "Pharmacien", value: "Cavp" },
                        { label: "Vétérinaire", value: "Carpv" },
                      ],
                    },
                    {
                      label: "Micro-entreprise",
                      options: [
                        { label: "Activité commerciale", value: "MicroRSI" },
                        { label: "Prestations de services (BIC)", value: "MicroBIC" },
                        { label: "Prestations de services (BNC)", value: "MicroBNC" },
                        { label: "Professions libérales réglementées ", value: "MicroCipav" },
                      ],
                    },
                  ]}
                  xlarge
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default PeriodeCumulEmploi;
