import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CheckBox from "components/ui/controls/check-box/check-box";
import TextField from "components/ui/controls/text-field/text-field";
import Button from "components/ui/controls/button/button";
import StateBox from "components/ui/controls/state-box/state-box";
import { FaExclamationTriangle, FaInfo } from "react-icons/fa";
import Hover from "components/ui/controls/hover/hover";

const RegmimesLiquidablesCumulEmploi = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <blockquote>Pour bénéficier du cumul emploi retraite intégral, le retraité doit avoir obtenu toutes ses retraites de base et complémentaires des régimes de retraite français, étrangers et des organisations internationales. Cette condition n’est pas exigée dans le cas où l’assuré pourrait bénéficier d’un droit personnel d'un régime dont l’âge d’ouverture des droits sans décote est supérieur à l'âge légal. Par défaut, les régimes sont considérés comme liquidés à la date du cumul emploi retraite. Vous pouvez toutefois décocher la case en face des régime concernés si vous ne souhaitez pas liquider les pensions à la date du CER.</blockquote>
              </Column>
            </Row>
            <Row tpad>
              <Column>
                <StateBox
                  label="AGIRC TC avant 2016"
                  inline
                  value={context.state["CumulEmploiLiquidationAgircTC" + ClientConjoint]}
                  onClick={() => context.handleValueChange("CumulEmploiLiquidationAgircTC" + ClientConjoint, !context.state["CumulEmploiLiquidationAgircTC" + ClientConjoint])}
                  normal
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default RegmimesLiquidablesCumulEmploi;
