import { useState, useContext, useEffect } from "react";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import { AppContext } from "components/app/RemExprertProvider";
import RachatLogo from "./images/Rachat.jpg";
import RetraiteProgressiveLogo from "./images/Retraite progressive.jpg";
import CERLogo from "./images/CER.jpg";
import RachatTrimestrePage from "./rachatTrimestre";
import RetraiteProgressivePage from "./retraiteProgressive";
import CumulEmploiRetraitePage from "./cumulEmploiReraite";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { CalculPrestationContrat } from "components/app/Api/Retraite/ApiRetraite";
// import AuditRetaiteLogo from "./images/Audit retraite.JPG";
// import AuditPrevoyanceLogo from "./images/Audit prevoyance.JPG";
// import AuditRemunerationLogo from "./images/Audit remuneration.JPG";

const SimulationRetraite = (props) => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [etude, setEtude] = useState("");
  useEffect(() => {
    ResultatTNS.GetResultBilanRetraite(context.state, "").then((ResultatRetraite) => {
      context.state.ListeContrat.forEach((contratRetraite, index) => {
        CalculPrestationContrat(context.state, "", ResultatRetraite, contratRetraite.Salaire, contratRetraite).then((Resultat) => {});
      });
    });
    if (context.state.InclureConjoint === true) {
      ResultatTNS.GetResultBilanRetraite(context.state, "Conjoint").then((ResultatRetraite) => {
        context.state.ListeContratConjoint.forEach((contratRetraite, index) => {
          CalculPrestationContrat(context.state, "Conjoint", ResultatRetraite, contratRetraite.Salaire, contratRetraite).then((Resultat) => {});
        });
      });
    }
    setEtude("");
  }, []);
  useEffect(() => {
    setEtude("");
  }, [props.ClientConjoint]);
console.log(props.ClientConjoint)
  return (
    <>
      {etude === "" && (
        <Column fill>
          <>
            <CardBox>
              <Column fill>
                <Row>
                  <h1>Simulations</h1>
                </Row>
                <Row>
                  <Column style={{ width: "170px", marginRight: "32px" }}>
                    <Button
                      style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                      onClick={() => {
                        setEtude("Rachat");
                      }}
                    >
                      <img style={{ height: "120px", borderRadius: "4px" }} src={RachatLogo} alt="..." />
                    </Button>
                  </Column>
                  {(ResultatTNS.state["Retraite" + props.ClientConjoint].InfosRetraiteChoixDepart?.TotalTrimestre >=
                    ResultatTNS.state["Retraite" + props.ClientConjoint].InfosRetraiteChoixDepart?.Infos?.DureeReference ||
                    ResultatTNS.state["Retraite" + props.ClientConjoint].InfosRetraiteChoixDepart?.Infos?.AgeDepartRetraite2 >=
                      ResultatTNS.state["Retraite" + props.ClientConjoint].InfosRetraiteChoixDepart?.Infos?.AgeTauxPlein) && (
                    <Column style={{ width: "170px", marginRight: "32px" }}>
                      <Button
                        style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                        onClick={() => {
                          setEtude("Cumul");
                        }}
                      >
                        <img style={{ height: "120px", borderRadius: "4px" }} src={CERLogo} alt="..." />
                      </Button>
                    </Column>
                  )}
                  {context.state["DisplayRetraiteProgressive" + props.ClientConjoint] === true && (
                    <Column style={{ width: "170px", marginRight: "32px" }}>
                      <Button
                        style={{ height: "120px", fontSize: "1.12rem", backgroundColor: "#579fdd" }}
                        onClick={() => {
                          setEtude("RetraiteProgressive");
                        }}
                      >
                        <img style={{ height: "120px", borderRadius: "4px" }} src={RetraiteProgressiveLogo} alt="..." />
                        {/* <span>Audit de retraite</span> */}
                      </Button>
                    </Column>
                  )}
                </Row>
              </Column>
            </CardBox>
          </>
        </Column>
      )}
      {etude === "Rachat" && <RachatTrimestrePage ClientConjoint={props.ClientConjoint} />}
      {etude === "RetraiteProgressive" && <RetraiteProgressivePage ClientConjoint={props.ClientConjoint} />}
      {etude === "Cumul" && <CumulEmploiRetraitePage ClientConjoint={props.ClientConjoint} accesSimulation={() => setEtude("")} />}
    </>
  );
};

export default SimulationRetraite;
