import _ from "lodash";
import { API_URL } from "paths";
export function RemoveUnecessaryData(Data) {
  let DataClone = _.cloneDeep(Data);
  delete DataClone.ListeClient;
  delete DataClone.InititalListeClient;
  delete DataClone.ListeDossier;
  delete DataClone.ListeContratPrevoyance;
  delete DataClone.Tabij1;
  delete DataClone.Tabij2;
  delete DataClone.Tabij4;
  delete DataClone.Tabij6;
  delete DataClone.CodesCourtier;
  delete DataClone.PrevTns2ContratLourmel;
  delete DataClone.PrevTns2ContratAprilGMPL;
  delete DataClone.PrevTns2ContratAprilPrevoyancePRO;
  delete DataClone.PrevTns2ContratVidens;
  delete DataClone.PrevTns2ContratSpvie;
  delete DataClone.PrevTns2ContratHodeva;
  delete DataClone.PrevTns2ContratSwisslifeSLPI;
  delete DataClone.PrevTns2ContratAlptis;
  delete DataClone.PrevTns2ContratAvivaSPP;
  delete DataClone.PrevTns2ContratAvivaSenseo;
  delete DataClone.ListeProspect;
  delete DataClone.TabPass;
  delete DataClone.PrevTns2RecueilBesoin;
  delete DataClone.ListeActiviteRetraite;
  delete DataClone.OptiRemResultatPrevoyance;
  delete DataClone.OptiRemResultatPrevoyanceConjoint;
  delete DataClone.OptiRemResultatRetraite;
  delete DataClone.OptiRemResultatRetraiteConjoint;
  delete DataClone.CumulEmploiResult;
  delete DataClone.CumulEmploiResultConjoint;
  // delete DataClone.OptiRemResultatRetraite;
  // delete DataClone.OptiRemResultatRetraite;
  // delete DataClone.OptiRemResultatPrevoyance;
  // delete DataClone.OptiRemResultatRetraiteConjoint;
  return DataClone;
}

export let PrintApiUrl = API_URL+'/';
export let ApiUrl = API_URL+"/ApiMako/V3/";
// if (window.location.href.indexOf("localhost") >= 0 || window.location.href.indexOf("test") >= 0) {
//   ApiUrl = "https://api-v3.mako-box.com/ApiMako/V3/";
// }